import styled from "styled-components";
import ButtonOutlineSmallPrimary from "~/components/ButtonsOutline/Small/Primary";
import ButtonSmallPrimary from "~/components/Buttons/Small/Primary";
import THEME_CONSTS from "~/consts/theme";

export const LabelInput = styled.div`
    font-family: "Inter";
    font-size: 17px;
    /* margin-left: 10px; */
    margin-bottom: 5px;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 22px;
`;

export const ButtonEveryPeriod = styled(ButtonOutlineSmallPrimary)`
    width: 100%;
    
    background-color: ${(props) => props.active && THEME_CONSTS.BUTTON_PRIMARY_BG_COLOR};
    color: ${(props) => props.active && "#fff"};

    height: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
`;

export const ButtonRelatorio = styled(ButtonSmallPrimary)`
    min-width: 245px;
    margin-left: 10px;
    background-color: ${(props) => props.selected && "#503ba7"};

    &:first-child {
        margin-left: 0;
    }
`;
