import React, { useState, useEffect, useRef } from "react";

import Select from "react-select";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm6, ColSm12 } from "~/components/Bootstrap/Col";

// COMPONENTES GERAIS
import Spinner from "~/components/Spinner";
import ButtonPrimary from "~/components/Buttons/Small/Primary";
import DateCalendarModalV2 from "~/components/DateCalendarModalV2";

// COMPONENTES INTERNOS
import { LabelInput, Footer } from "../styles";

// SERVICES
import APIRequests from "~/services/requests/painel";
import APIRequestsFranquias from "~/services/requests/franquias";
import AuthService from "~/services/auth";

// UTILS
import Utils from "~/utils";
import moment from "~/utils/moment";
import NotificacaoUtil from "~/utils/notificacao.js";
import InputNormalDateTime from "~/components/Inputs/Normal/DateTime";

export default function RelatorioDeAcessosLoja() {
    /**
     * Referência do modal
     */
    const RefModalData = useRef(null);

    /**
     * Datas no formato americano
     */
    const StartDateUS = moment().format("YYYY-MM-DD 00:00:00");
    const EndDateUS = moment().format("YYYY-MM-DD HH:mm:ss");

    /**
     * Carregando
     */
    const [Loading, setLoading] = useState(false);

    /**
     * Datas do filtro
     */
    const [DataInicio, setDataInicio] = useState(StartDateUS);
    const [DataFim, setDataFim] = useState(EndDateUS);

    /**
     * Emitentes Disponíveis
     */
    const [EmitentesDisponiveis, setEmitentesDisponiveis] = useState([]);
    const [UnidadesDisponiveis, setUnidadesDisponiveis] = useState([]);

    const [EmitenteSelecionado, setEmitenteSelecionado] = useState();
    const [UnidadesSelecionadas, setUnidadesSelecionadas] = useState([]);

    /**
     * Envia dados para gerar pdf
     */
    const onGeneratePDF = async () => {
        try {
            setLoading(true);

            if (!EmitenteSelecionado?.value) {
                throw new Error("Selecione um emitente para gerar o relatório");
            }

            const data = {
                emitente: Utils.SomenteNumeros(EmitenteSelecionado.value),
                unidades: [UnidadesSelecionadas.value],
                periodo: {
                    inicio: DataInicio,
                    fim: DataFim,
                },
            };

            const response = await APIRequestsFranquias.relatorios.acessos(data);

            if (response.url) {
                window.open(response.url);
            } else {
                throw new Error("Erro ao gerar o relatório");
            }
        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const goLoadUnidades = async () => {
        try {
            setUnidadesDisponiveis([]);
            if (!EmitenteSelecionado?.value) return;

            const unidades = await APIRequestsFranquias.unidades.listar({ company: EmitenteSelecionado?.value });

            if (unidades.results) {
                const options = unidades.results.map((reg) => ({
                    label: `${reg.nome_unidade} (${reg.logradouro} ${reg.numero}, ${reg.bairro}, ${reg.municipio} - ${reg.uf})`,
                    value: reg.id,
                }));

                setUnidadesDisponiveis(options);
            }
        } catch (error) {}
    };

    /**
     * Carrega as unidades, quando seleciona o emitente
     */
    useEffect(() => {
        goLoadUnidades();
    }, [EmitenteSelecionado]);

    /**
     * Carrega os emitentes
     */
    useEffect(() => {
        const user = AuthService.getUserData();

        const emitentes = user?.emitentes ?? [];

        /**
         * Gera a array com os novos dados.
         */
        const options = emitentes.map((reg) => ({
            label: `${reg.emitente_dados.nome} - ${reg.emitente_dados.cpf_cnpj}`,
            value: Utils.SomenteNumeros(reg.emitente_dados.cpf_cnpj),

            ...reg.emitente_dados,
        }));

        /**
         * Coloca na array
         */
        setEmitentesDisponiveis(options);
    }, []);

    return (
        <div id="report">
            <div id="box">
                <Row>
                    <ColSm12>
                        <LabelInput>Emitente</LabelInput>
                        <Select
                            autoFocus
                            options={EmitentesDisponiveis}
                            value={EmitenteSelecionado}
                            placeholder="Pesquise o emitente para gerar o relatório"
                            onChange={(option) => {
                                setEmitenteSelecionado(option);
                                setUnidadesSelecionadas([]);
                            }}
                        />
                    </ColSm12>
                </Row>

                <Row>
                    <ColSm12>
                        <LabelInput>Unidades</LabelInput>
                        <Select
                            isDisabled={!EmitenteSelecionado?.value}
                            options={UnidadesDisponiveis}
                            placeholder={!EmitenteSelecionado?.value ? "Selecione um emitente" : "Pesquise as unidades que você quer gerar o relatório"}
                            onChange={(option) => {
                                setUnidadesSelecionadas(option);
                            }}
                        />
                    </ColSm12>
                </Row>

                <Row>
                    <ColSm6>
                        <LabelInput>Periodo - Início</LabelInput>
                        <InputNormalDateTime defaultDate={DataInicio} onChange={(date) => setDataInicio(date)} />
                    </ColSm6>
                    <ColSm6>
                        <LabelInput>Periodo - Fim</LabelInput>
                        <InputNormalDateTime defaultDate={DataFim} onChange={(date) => setDataFim(date)} />
                    </ColSm6>
                </Row>
                <br />
            </div>

            <Footer>
                <ButtonPrimary disabled={Loading || !UnidadesSelecionadas?.value} onClick={onGeneratePDF}>
                    {Loading ? <Spinner color="#fff" size={14} /> : "GERAR PDF"}
                </ButtonPrimary>
            </Footer>

            <DateCalendarModalV2 ref={RefModalData} />
        </div>
    );
}
