import React, { useState } from "react";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle } from "~/components/BasePage";

// STYLES
import { ButtonRelatorio } from "./styles";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";

// COMPONENTES RELATÓRIOS
import RelatorioDeVendas from "./sub/Vendas";
import RelatorioDeTransacoesTEF from "./sub/TransacoesTEF";
import RelatorioDeTransacoesTEFErro from "./sub/TransacoesTEFErro";
import RelatorioDeAcessosLoja from "./sub/AcessosLoja";
export default function PageRelatorios() {
    const [RelatorioSelecionado, setRelatorioSelecionado] = useState("");

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">
                <BasePageTitleContainer>
                    <BasePageTitle>Relatórios</BasePageTitle>
                </BasePageTitleContainer>

                <BasePageBox id="box">
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        <ButtonRelatorio selected={RelatorioSelecionado == "VENDAS"} onClick={() => setRelatorioSelecionado("VENDAS")}>
                            Vendas
                        </ButtonRelatorio>

                        <ButtonRelatorio selected={RelatorioSelecionado == "TRANSACOES_TEF"} onClick={() => setRelatorioSelecionado("TRANSACOES_TEF")}>
                            Transações TEF
                        </ButtonRelatorio>

                        <ButtonRelatorio selected={RelatorioSelecionado == "TRANSACOES_TEF_ERRO"} onClick={() => setRelatorioSelecionado("TRANSACOES_TEF_ERRO")}>
                            Transações TEF com Erro
                        </ButtonRelatorio>

                        <ButtonRelatorio selected={RelatorioSelecionado == "ACESSOS_LOJA"} onClick={() => setRelatorioSelecionado("ACESSOS_LOJA")}>
                            Acessos loja
                        </ButtonRelatorio>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        {RelatorioSelecionado == "VENDAS" && <RelatorioDeVendas />}
                        {RelatorioSelecionado == "ACESSOS_LOJA" && <RelatorioDeAcessosLoja />}
                        {RelatorioSelecionado == "TRANSACOES_TEF" && <RelatorioDeTransacoesTEF />}
                        {RelatorioSelecionado == "TRANSACOES_TEF_ERRO" && <RelatorioDeTransacoesTEFErro />}
                    </div>
                </BasePageBox>
            </BasePageContainer>
        </BasePageMain>
    );
}
