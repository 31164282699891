// SUB
import Unidades from "./unidades";
import Relatorios from "./relatorios";

const APIRequestsFranquias = {
    unidades: Unidades,
    relatorios: Relatorios,
};

export default APIRequestsFranquias;
