// API
import api from "~/services/api-franquias";

const Listar = async ({ company, query = "", limit = 100, page = 1 }) => {
    try {
        const query_params_arr = [];

        query_params_arr.push(`query=${query}`);
        query_params_arr.push(`limit=${limit}`);
        query_params_arr.push(`page=${page}`);

        const { data: response_data } = await api.get(`/franqueado/${company}/unidades?${query_params_arr.join("&")}`, { responseType: "json" });

        return response_data;
    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }
};

const FranquiasUnidadesRequests = {
    listar: Listar,
};

export default FranquiasUnidadesRequests;
