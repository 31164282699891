// API
import api from "~/services/api-franquias";

const Acessos = async (data) => {
    try {
        const { data: response_data } = await api.post(`/relatorios/acessos_unidade`, data);

        return response_data;
    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }
};

const FranquiasRelatoriosRequests = {
    acessos: Acessos,
};

export default FranquiasRelatoriosRequests;
