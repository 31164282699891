import axios from "axios";

const apiFranquias = axios.create({
    timeout: 100000, // valor alto por causa do relatório
    headers: {
        "Content-type": "application/json",
    },
});

// INTERCEPTA A CONEXÃO AXIOS
apiFranquias.interceptors.request.use(
    async function (config) {
        const development = process.env.NODE_ENV != "production";

        if (development) {
            // pega o endpoint das variáveis de ambiente
            const endpoint = process.env.REACT_APP_DEV_FRANQUIAS_URL ?? "localhost";
            // configuração para homologação
            config.baseURL = `http://${endpoint}/v1`;
        } else {
            // se estiver no modo demo não usa a api da configuração
            config.baseURL = `https://api-app-franquias.linvix.com/v1`;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default apiFranquias;
